@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://sky.blackbaudcdn.net/static/skyux-icons/3.0.0-beta.1/assets/css/skyux-icons.min.css");
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/* HTML5 display definitions
       ========================================================================== */
/**
     * Add the correct display in IE <10.
     * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
     * Add the correct display in IE for `main`.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
     * Add the correct display in IE <10.
     */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
     * Add the correct display and remove excess height in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  vertical-align: baseline; }

/**
     * Add the correct display in IE <11, Safari <8, and Firefox <22.
     * 1. Add the correct display in IE.
     */
template,
[hidden] {
  display: none; }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox <40.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/**
     * Add the correct font style in Android <4.4.
     */
dfn {
  font-style: italic; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0; }

/**
     * Add the correct background and color in IE <10.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE <11.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
       ========================================================================== */
/**
     * Known issues:
     * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
     *   select, unless a border property is set. The default font weight on
     *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
     *   OS X.
     * - It is recommended that you do not style checkbox and radio inputs as
     *   Firefox's implementation does not respect box-sizing, padding, or width.
     * - Certain font size values applied to number inputs cause the cursor style of
     *   the decrement button to change from default to text.
     * - The search input is not fully stylable by default. In Chrome and Safari on
     *   OSX/iOS you can't control font, padding, border, or background. In Chrome
     *   and Safari on Windows you can't control border properly. It will apply
     *   border-width but will only show a border color (which cannot be controlled)
     *   for the outer 1px of that border. Applying -webkit-appearance: textfield
     *   addresses these issues without removing the benefits of search inputs (e.g.
     *   showing past searches). Safari (but not Chrome) will clip the cancel button
     *   on when it has padding (and textfield appearance).
     */
/**
     * 1. Change font properties to `inherit` in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 4. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE <11.
     * 2. Remove the padding in IE <11.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     * 4. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */ }

/**
     * Restore the font weight unset by a previous rule.
     */
optgroup {
  font-weight: bold; }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

.sky-border-bottom-dark {
  border-bottom: 1px solid #cdcfd2; }

.sky-border-bottom-light {
  border-bottom: 1px solid #e2e3e4; }

.sky-border-bottom-row {
  border-bottom: 1px dotted #cdcfd2; }

.sky-border-dark {
  border-top: 1px solid #cdcfd2;
  border-bottom: 1px solid #cdcfd2;
  border-left: 1px solid #cdcfd2;
  border-right: 1px solid #cdcfd2; }

.sky-border-light {
  border-top: 1px solid #e2e3e4;
  border-bottom: 1px solid #e2e3e4;
  border-left: 1px solid #e2e3e4;
  border-right: 1px solid #e2e3e4; }

button:active {
  outline: 0;
  background-image: none; }

[role="button"] {
  cursor: pointer; }

.sky-btn {
  background-color: transparent;
  border-top: 1px solid #cdcfd2;
  border-bottom: 1px solid #cdcfd2;
  border-left: 1px solid #cdcfd2;
  border-right: 1px solid #cdcfd2;
  border-radius: 3px;
  color: #212327;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  padding: 6px 12px;
  line-height: 1.42857;
  font-size: 15px; }
  .sky-btn:hover {
    border-top: 1px solid #e2e3e4;
    border-bottom: 1px solid #e2e3e4;
    border-left: 1px solid #e2e3e4;
    border-right: 1px solid #e2e3e4;
    color: #212327; }
  .sky-btn.sky-btn-disabled, .sky-btn[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
    box-shadow: none; }

a.sky-btn:hover {
  text-decoration: none; }

.sky-btn-default {
  color: #212327;
  background-color: #ffffff;
  border-color: #cdcfd2; }
  .sky-btn-default:hover, .sky-btn-default:active, .sky-btn-default.sky-btn-active {
    color: #212327;
    background-color: #eeeeef;
    border-color: #b2b5ba; }
  .sky-btn-default:active, .sky-btn-default.sky-btn-active {
    background-image: none; }
  .sky-btn-default.sky-btn-disabled, .sky-btn-default.sky-btn-disabled:hover, .sky-btn-default.sky-btn-disabled:focus, .sky-btn-default.sky-btn-disabled.sky-btn-focus, .sky-btn-default.sky-btn-disabled:active, .sky-btn-default.sky-btn-disabled.sky-btn-active, .sky-btn-default[disabled], .sky-btn-default[disabled]:hover, .sky-btn-default[disabled]:focus, .sky-btn-default[disabled].sky-btn-focus, .sky-btn-default[disabled]:active, .sky-btn-default[disabled].sky-btn-active,
  fieldset[disabled] .sky-btn-default,
  fieldset[disabled] .sky-btn-default:hover,
  fieldset[disabled] .sky-btn-default:focus,
  fieldset[disabled] .sky-btn-default.sky-btn-focus,
  fieldset[disabled] .sky-btn-default:active,
  fieldset[disabled] .sky-btn-default.sky-btn-active {
    background-color: #ffffff;
    border-color: #cdcfd2; }

.sky-btn-primary {
  color: #ffffff;
  background-color: #0974a1;
  border-color: #0974a1; }
  .sky-btn-primary:hover, .sky-btn-primary:active, .sky-btn-primary.sky-btn-active {
    color: #ffffff;
    background-color: #065171;
    border-color: #065171; }
  .sky-btn-primary:active, .sky-btn-primary.sky-btn-active {
    background-image: none; }
  .sky-btn-primary.sky-btn-disabled, .sky-btn-primary.sky-btn-disabled:hover, .sky-btn-primary.sky-btn-disabled:focus, .sky-btn-primary.sky-btn-disabled.sky-btn-focus, .sky-btn-primary.sky-btn-disabled:active, .sky-btn-primary.sky-btn-disabled.sky-btn-active, .sky-btn-primary[disabled], .sky-btn-primary[disabled]:hover, .sky-btn-primary[disabled]:focus, .sky-btn-primary[disabled].sky-btn-focus, .sky-btn-primary[disabled]:active, .sky-btn-primary[disabled].sky-btn-active,
  fieldset[disabled] .sky-btn-primary,
  fieldset[disabled] .sky-btn-primary:hover,
  fieldset[disabled] .sky-btn-primary:focus,
  fieldset[disabled] .sky-btn-primary.sky-btn-focus,
  fieldset[disabled] .sky-btn-primary:active,
  fieldset[disabled] .sky-btn-primary.sky-btn-active {
    background-color: #0974a1;
    border-color: #0974a1; }

.sky-btn-danger {
  color: #ffffff;
  background-color: #d93a3d;
  border-color: #d93a3d; }
  .sky-btn-danger:hover, .sky-btn-danger:active, .sky-btn-danger.sky-btn-active {
    color: #ffffff;
    background-color: #bc2427;
    border-color: #bc2427; }
  .sky-btn-danger:active, .sky-btn-danger.sky-btn-active {
    background-image: none; }
  .sky-btn-danger.sky-btn-disabled, .sky-btn-danger.sky-btn-disabled:hover, .sky-btn-danger.sky-btn-disabled:focus, .sky-btn-danger.sky-btn-disabled.sky-btn-focus, .sky-btn-danger.sky-btn-disabled:active, .sky-btn-danger.sky-btn-disabled.sky-btn-active, .sky-btn-danger[disabled], .sky-btn-danger[disabled]:hover, .sky-btn-danger[disabled]:focus, .sky-btn-danger[disabled].sky-btn-focus, .sky-btn-danger[disabled]:active, .sky-btn-danger[disabled].sky-btn-active,
  fieldset[disabled] .sky-btn-danger,
  fieldset[disabled] .sky-btn-danger:hover,
  fieldset[disabled] .sky-btn-danger:focus,
  fieldset[disabled] .sky-btn-danger.sky-btn-focus,
  fieldset[disabled] .sky-btn-danger:active,
  fieldset[disabled] .sky-btn-danger.sky-btn-active {
    background-color: #d93a3d;
    border-color: #d93a3d; }

.sky-btn-link, .sky-btn-link-inline {
  color: #0974a1;
  background-color: transparent;
  border-color: transparent; }

.sky-btn-link:hover, .sky-btn-link-inline:hover {
  color: #065171;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  outline: none; }

.sky-btn-link-inline {
  font-size: inherit;
  line-height: inherit;
  display: inline;
  padding: 0;
  border: none;
  vertical-align: top; }

.sky-btn-link-inline:hover {
  border: none; }

.sky-btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.sky-btn.sky-btn-active {
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.sky-btn-tab {
  background-color: transparent;
  border: none;
  border-radius: 4px 4px 0 0;
  color: #686c73;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 1.8;
  padding: 8px 16px; }
  .sky-btn-tab:hover:not(.sky-btn-tab-disabled) {
    background-color: #eeeeef;
    color: #212327;
    text-decoration: none; }
  .sky-btn-tab.sky-btn-tab-disabled {
    background-color: #cdcfd2;
    cursor: default;
    outline: none;
    text-decoration: none; }
    .sky-btn-tab.sky-btn-tab-disabled:hover {
      color: #686c73;
      cursor: default;
      text-decoration: none; }

.sky-btn-tab-close:not(.sky-btn-tab-close-disabled) {
  background-color: transparent;
  border: none;
  color: #686c73;
  cursor: pointer;
  line-height: 1.4;
  margin-left: 5px; }
  .sky-btn-tab-close:not(.sky-btn-tab-close-disabled):hover {
    color: #212327;
    transition: color 150ms; }

.sky-btn-tab-close-disabled {
  background-color: transparent;
  border: none;
  color: #686c73;
  line-height: 1.4;
  margin-left: 5px; }

.sky-tab-header-count {
  font-weight: 400;
  color: #0974a1;
  margin-left: 5px; }

.sky-btn-tab-selected:not(.sky-btn-tab-disabled) {
  background-color: #0974a1;
  color: #fff; }
  .sky-btn-tab-selected:not(.sky-btn-tab-disabled):hover:not(.sky-btn-tab-disabled) {
    background-color: #0974a1;
    color: #fff; }
  .sky-btn-tab-selected:not(.sky-btn-tab-disabled) .sky-btn-tab-close, .sky-btn-tab-selected:not(.sky-btn-tab-disabled) .sky-tab-header-count {
    color: #fff; }

.sky-btn-tab-wizard {
  background-color: #ffffff;
  border-top: 1px solid #e2e3e4;
  border-bottom: 1px solid #e2e3e4;
  border-left: 1px solid #e2e3e4;
  border-right: 1px solid #e2e3e4;
  border-radius: 3px;
  font-weight: 400;
  color: #212327;
  line-height: 1.6em;
  margin-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px; }
  .sky-btn-tab-wizard:hover, .sky-btn-tab-wizard:focus {
    text-decoration: none;
    color: #212327;
    background-color: #eeeeef; }
  .sky-btn-tab-wizard.sky-btn-tab-selected {
    background-color: #81d4f7;
    border-color: #81d4f7;
    color: #212327; }
  .sky-btn-tab-wizard.sky-btn-tab-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #eeeeef;
    color: #686c73;
    box-shadow: none; }

.sky-btn-borderless, .sky-btn-borderless-inline {
  color: #cdcfd2;
  cursor: pointer;
  border: none; }
  .sky-btn-borderless:hover, .sky-btn-borderless-inline:hover {
    color: #979ba2;
    transition: color 150ms; }

.sky-btn-borderless:hover, .sky-btn-borderless-inline:hover {
  border: none; }

.sky-btn-borderless-inline {
  font-size: inherit;
  line-height: inherit;
  display: inline;
  padding: 0;
  vertical-align: top; }

.sky-btn-borderless-inline:hover {
  border: none; }

.sky-container {
  margin: 0 auto;
  padding: 0 15px; }

@media (min-width: 768px) {
  .sky-container {
    width: 758px; } }

@media (min-width: 992px) {
  .sky-container {
    width: 972px; } }

@media (min-width: 1200px) {
  .sky-container {
    width: 1190px; } }

.sky-responsive-container-xs .sky-container {
  width: auto; }

.sky-responsive-container-sm .sky-container {
  width: 758px; }

.sky-responsive-container-md .sky-container {
  width: 972px; }

.sky-responsive-container-lg .sky-container {
  width: 1190px; }

/* Dragula styles */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.gu-hide {
  display: none !important; }

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

.gu-transit {
  opacity: 0.5;
  filter: alpha(opacity=20); }

/* End Dragula styles */
@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-semi-bold.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 700;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-bold.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: italic;
  font-weight: 400;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-italic.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-light.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans';
  font-style: normal;
  font-weight: 100;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-thin.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-condensed-light.woff") format("woff"); }

@font-face {
  font-family: 'Blackbaud Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: url("https://sky.blackbaudcdn.net/skyux-fonts/1.0.1/blackbaud-sans-condensed-semi-bold.woff") format("woff"); }

.sky-form-control {
  width: 100%;
  padding: 6px 12px;
  border-top: 1px solid #cdcfd2;
  border-bottom: 1px solid #cdcfd2;
  border-left: 1px solid #cdcfd2;
  border-right: 1px solid #cdcfd2;
  line-height: 1.42857;
  font-size: 15px;
  color: #212327; }
  .sky-form-control:not([type="range"]):not([type="radio"]):not([type="checkbox"]):not(select) {
    /* Remove device-specific styles */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0; }
  .sky-form-control:focus {
    box-shadow: 0 0 8px rgba(0, 180, 241, 0.6);
    border: 1px solid #00b4f1;
    outline: none; }
  .sky-form-control:disabled {
    background-color: #cdcfd2; }

.sky-form-group {
  margin-bottom: 10px; }

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  box-shadow: 0 0 8px rgba(239, 64, 68, 0.6);
  border: 1px solid #ef4044;
  outline: none; }

.sky-error-label {
  margin-top: 5px;
  color: #212327; }

.sky-error-label::before {
  content: "\f071";
  font-family: FontAwesome;
  margin-right: 5px;
  color: #ef4044; }

.sky-control-label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%; }

.sky-control-label-required:after {
  content: "*";
  color: #ef4044;
  padding-left: 5px; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1IDEwIiB3aWR0aD0iNSIgaGVpZ2h0PSIxMCI+Cgk8c3R5bGU+CgkJdHNwYW4geyB3aGl0ZS1zcGFjZTpwcmUgfQoJCS5zaHAwIHsgZmlsbDogIzQ0NDQ0NCB9IAoJPC9zdHlsZT4KCTxwYXRoIGlkPSJMYXllciIgY2xhc3M9InNocDAiIGQ9Ik0xLjQxIDQuNjdMMi40OCAzLjE4TDMuNTQgNC42N0wxLjQxIDQuNjdMMS40MSA0LjY3WiIgLz4KCTxwYXRoIGlkPSJMYXllciIgY2xhc3M9InNocDAiIGQ9Ik0zLjU0IDUuMzNMMi40OCA2LjgyTDEuNDEgNS4zM0wzLjU0IDUuMzNMMy41NCA1LjMzWiIgLz4KPC9zdmc+) no-repeat right 50%;
  background-size: 15px 30px;
  border-top: 1px solid #e2e3e4;
  border-bottom: 1px solid #e2e3e4;
  border-left: 1px solid #e2e3e4;
  border-right: 1px solid #e2e3e4;
  border-radius: 3px;
  font-size: 15px;
  height: 34px;
  padding: 5px 21px 5px 10px; }

@-moz-document url-prefix() {
  select {
    padding: 5px 17px 5px 7px; } }

select::-ms-expand {
  display: none; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select {
    padding: 5px 19px 5px 8px; } }

@supports (-ms-ime-align: auto) {
  select {
    padding: 5px 18px 5px 7px; } }

::-webkit-input-placeholder {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px;
  font-style: italic;
  opacity: 1; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px;
  font-style: italic;
  opacity: 1; }

input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px;
  font-style: italic;
  opacity: 1; }

:-ms-input-placeholder {
  /* IE */
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px;
  font-style: italic;
  opacity: 1; }

.sky-input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%; }
  .sky-input-group > .sky-form-control {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 1%;
    align-items: center;
    z-index: 2; }

.sky-input-group-btn {
  position: relative;
  display: flex;
  align-items: stretch;
  white-space: nowrap; }
  .sky-input-group-btn:first-child > .sky-btn,
  .sky-input-group-btn:last-child > .sky-btn:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .sky-input-group-btn:not(:first-child):not(:last-child) > .sky-btn {
    border-radius: 0; }
  .sky-input-group-btn:last-child > .sky-btn,
  .sky-input-group-btn:first-child > .sky-btn:not(:first-child),
  .sky-input-group-btn:not(:first-child) > .sky-btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .sky-input-group-btn > .sky-btn {
    margin-left: -1px; }

.sky-modal-body-open {
  overflow: hidden;
  position: relative; }

.sky-palette-multi-1 {
  background-color: #08c0ff; }

.sky-palette-multi-2 {
  background-color: #fbb94c; }

.sky-palette-multi-3 {
  background-color: #7dc453; }

.sky-palette-multi-4 {
  background-color: #ae9abc; }

.sky-palette-multi-5 {
  background-color: #00d1aa; }

.sky-palette-multi-6 {
  background-color: #f1575a; }

.sky-palette-multi-7 {
  background-color: #91959c; }

.sky-palette-multi-8 {
  background-color: #73dcff; }

.sky-palette-multi-9 {
  background-color: #fdd189; }

.sky-palette-multi-10 {
  background-color: #b6de9e; }

.sky-palette-multi-11 {
  background-color: #cfc3d7; }

.sky-palette-multi-12 {
  background-color: #55ffdf; }

.sky-palette-multi-13 {
  background-color: #f69093; }

.sky-palette-multi-14 {
  background-color: #b0b2b7; }

.sky-palette-mono-1 {
  background-color: #0087b5; }

.sky-palette-mono-2 {
  background-color: #00b4f1; }

.sky-palette-mono-3 {
  background-color: #35ccff; }

.sky-palette-mono-4 {
  background-color: #79ddff; }

.sky-palette-mono-5 {
  background-color: #bceeff; }

.sky-padding-even-default {
  padding: 10px 10px 10px 10px; }

.sky-padding-even-small {
  padding: 8px 8px 8px 8px; }

.sky-padding-even-large {
  padding: 15px 15px 15px 15px; }

.sky-padding-squish-default {
  padding: 5px 10px 5px 10px; }

.sky-padding-squish-small {
  padding: 3px 8px 3px 8px; }

.sky-padding-squish-large {
  padding: 8px 15px 8px 15px; }

.sky-margin-inline-default {
  margin-right: 10px; }

.sky-margin-inline-compact {
  margin-right: 5px; }

.sky-margin-stacked-default {
  margin-bottom: 10px; }

.sky-margin-stacked-compact {
  margin-bottom: 5px; }

.sky-margin-stacked-separate {
  margin-bottom: 20px; }

.sky-padding-even-sm {
  padding: 10px; }

.sky-padding-even-md {
  padding: 15px; }

.sky-padding-even-lg {
  padding: 20px; }

.sky-padding-even-xl {
  padding: 20px; }

.sky-padding-vertical-md {
  padding: 15px 0; }

.sky-padding-vertical-lg {
  padding: 20px 0; }

.sky-margin-inline-xs {
  margin-right: 5px; }

.sky-margin-inline-sm {
  margin-right: 10px; }

.sky-margin-inline-xl {
  margin-right: 20px; }

.sky-margin-inline-xxl {
  margin-right: 20px; }

.sky-margin-stacked-xs {
  margin-bottom: 5px; }

.sky-margin-stacked-md {
  margin-bottom: 15px; }

.sky-margin-stacked-lg {
  margin-bottom: 20px; }

.sky-margin-stacked-xl {
  margin-bottom: 20px; }

.sky-margin-stacked-xxl {
  margin-bottom: 20px; }

.sky-switch {
  cursor: pointer;
  display: inline-flex; }
  .sky-switch:hover .sky-switch-control {
    border-color: #00b4f1;
    border-width: 2px; }

.sky-switch-disabled {
  cursor: default; }
  .sky-switch-disabled input {
    cursor: default; }

.sky-switch-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none; }
  .sky-switch-input:checked + .sky-switch-control {
    background-color: #c1e8fb;
    border-color: #00b4f1;
    border-width: 2px; }
    .sky-switch-input:checked + .sky-switch-control.sky-switch-control-success {
      background-color: #d2e8c0;
      border-color: #72bf44; }
    .sky-switch-input:checked + .sky-switch-control.sky-switch-control-warning {
      background-color: #ffeaca;
      border-color: #fbb034; }
    .sky-switch-input:checked + .sky-switch-control.sky-switch-control-danger {
      background-color: #fac8bf;
      border-color: #ef4044; }
  .sky-switch-input:disabled + .sky-switch-control {
    background-color: #cdcfd2;
    border-color: transparent; }
  .sky-switch-input:focus + .sky-switch-control {
    outline: thin dotted;
    outline: -webkit-focus-ring-color auto 5px; }

.sky-switch-control {
  width: 22px;
  max-width: 22px;
  height: 22px;
  flex: 1 0 22px;
  margin: 0 5px auto auto;
  display: inline-flex;
  position: relative;
  border: 1px solid #cdcfd2;
  background-color: #ffffff;
  color: #212327;
  text-align: center;
  line-height: 1;
  align-items: center;
  justify-content: center; }
  .sky-switch-control.sky-switch-control-icon {
    max-width: none;
    width: 35px;
    height: 35px;
    flex: 1 0 35px;
    font-size: 18px; }
  .sky-switch-control::before {
    content: ''; }

.sky-switch-label {
  line-height: 22px;
  flex: 1 1 auto;
  width: 100%;
  margin-right: 10px;
  min-width: 0; }

.sky-switch-icon-group {
  display: flex; }
  .sky-switch-icon-group .sky-switch-control-icon {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0; }
  .sky-switch-icon-group sky-radio:first-of-type .sky-switch-control-icon, .sky-switch-icon-group sky-checkbox:first-of-type .sky-switch-control-icon {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .sky-switch-icon-group sky-radio:last-of-type .sky-switch-control-icon, .sky-switch-icon-group sky-checkbox:last-of-type .sky-switch-control-icon {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }

* {
  box-sizing: border-box; }

body,
form,
html {
  font-size: 15px;
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif; }

body {
  background-color: #eeeeef;
  color: #212327; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.1; }

h2 {
  font-size: 26px;
  font-weight: 300; }

h3 {
  font-size: 19px;
  color: #686c73; }

h4 {
  font-size: 14px; }

h5 {
  font-size: 12px; }

h6 {
  font-size: 10px; }

h4,
h5,
h6 {
  font-weight: 600; }

h1 {
  font-size: 34px;
  font-weight: 300; }

[hidden] {
  display: none !important; }

a {
  color: #0974a1;
  text-decoration: none; }
  a:hover {
    color: #065171;
    text-decoration: underline; }

.sky-pull-right {
  float: right; }

.sky-list-unstyled {
  list-style: none;
  padding: 0; }
  .sky-list-unstyled li {
    padding-bottom: 5px; }

mark.sky-highlight-mark {
  background-color: #fbb034;
  color: #212327; }

.sky-rounded-corners {
  border-radius: 3px; }

.sky-rounded-circle {
  border-radius: 50%; }

.sky-shadow {
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.3); }

.sky-field-status-active {
  box-shadow: 0 0 8px rgba(0, 180, 241, 0.6);
  border: 1px solid #00b4f1;
  outline: none; }

.sky-field-status-invalid {
  box-shadow: 0 0 8px rgba(239, 64, 68, 0.6);
  border: 1px solid #ef4044;
  outline: none; }

.sky-field-status-success {
  box-shadow: 0 0 8px rgba(114, 191, 68, 0.6);
  border: 1px solid #72bf44;
  outline: none; }

.sky-page-heading {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 300;
  font-size: 34px; }

.sky-section-heading {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 300;
  font-size: 26px; }

.sky-subsection-heading {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 300;
  font-size: 19px; }

.sky-headline {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 600;
  font-size: 22px; }

.sky-emphasized {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #212327;
  font-weight: 600;
  font-size: 16px; }

.sky-deemphasized {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px;
  font-style: italic; }

.sky-field-label {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px; }

.sky-text-info {
  color: #212327; }
  .sky-text-info:before {
    content: "\f06a";
    font-family: FontAwesome;
    margin-right: 5px;
    color: #00b4f1; }

.sky-text-success {
  color: #212327; }
  .sky-text-success:before {
    content: "\f00c";
    font-family: FontAwesome;
    margin-right: 5px;
    color: #72bf44; }

.sky-text-warning {
  color: #212327; }
  .sky-text-warning:before {
    content: "\f071";
    font-family: FontAwesome;
    margin-right: 5px;
    color: #fbb034; }

.sky-text-danger {
  color: #212327; }
  .sky-text-danger:before {
    content: "\f071";
    font-family: FontAwesome;
    margin-right: 5px;
    color: #ef4044; }

.sky-font-body-default {
  font-size: 15px;
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #212327; }

.sky-font-body-sm {
  font-size: 13px;
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #212327; }

.sky-font-body-lg {
  font-size: 16px;
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #212327; }

.sky-font-field-label {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px; }

.sky-font-deemphasized {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px;
  font-style: italic; }

.sky-font-emphasized {
  font-weight: 600; }

.sky-font-heading-1 {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 300;
  font-size: 34px; }

.sky-font-heading-2 {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 300;
  font-size: 26px; }

.sky-font-heading-3 {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 300;
  font-size: 19px; }

.sky-font-heading-4 {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #212327;
  font-weight: 600;
  font-size: 16px; }

.sky-font-heading-5 {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #686c73;
  font-weight: 400;
  font-size: 15px; }

.sky-font-display-1 {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 300;
  font-size: 34px; }

.sky-font-display-2 {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 300;
  font-size: 26px; }

.sky-font-display-3 {
  font-family: "Blackbaud Sans Condensed", "Helvetica Neue Condensed", "Arial Narrow";
  color: #212327;
  font-weight: 600;
  font-size: 22px; }

.sky-font-display-4 {
  font-family: "Blackbaud Sans", "Helvetica Neue", Arial, sans-serif;
  color: #212327;
  font-weight: 600;
  font-size: 16px; }
